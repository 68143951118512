export class Feature {
	/**
	 * This should be updated for applications that require fully responsive configurations.
	 * 
	 * In order for use to make use of this feature, we need to add the `breakpoints: IBreakpointProps<IComponentConfigurations>[]` to `src/models/app/IConfiguration.ts`.
	 * 
	 * NOTE: That by enabling this feature, we will need to confirm that no breakpoint configurations are nested within the components configuration, 
	 * and thus cleaning up any components that made use of those nested breakpoint configs.
	 * 
	 * @static
	 */
	static useResponsiveComponentConfiguration: boolean = false;
}