import { StoreContext } from '../../../configuration/StoreContext';
import { action, computed } from 'mobx';
import { TSelectionFieldOnChange } from '../../../models/app/TSelectionFieldOnChange';
import { ISelectionFieldBase } from '../../../models/app/ISelectionFieldBase';

export class SelectionFieldStore {
	storeContext: StoreContext;
	constructor(storeContext: StoreContext) {
		this.storeContext = storeContext;
	}

	@computed
	get configuration() {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.configuration;
		}
	}

	@computed
	get onChangeOptions() {
		return this.configuration && this.configuration.onChangeOptions;
	}

	@action
	handleFieldOnChange = (field: ISelectionFieldBase, value: any) => {
		const { onChange } = field;
		if (this.storeContext && this.storeContext.acceptanceStore) {
			// reset the accepted state to prevent previous states from defaulting when changing field values
			this.storeContext.acceptanceStore.resetAcceptedState();
		}
		if (onChange) {
			const { selectionStore } = this.storeContext;
			const { functionName } = onChange;
			if (this[functionName]) {
				this[functionName](selectionStore.rawValues, onChange);
			}
		}
	}

	@action
	cultureChange = async (values: any, onChange: TSelectionFieldOnChange) => {
		if (onChange.functionName === 'cultureChange') {
			const { appStore, translationStore, disclaimerStore, selectionStore, footnoteStore, acceptanceDisclaimerStore } = this.storeContext;
			if (translationStore && selectionStore) {
				const { options } = onChange;
				const cultureChangeOptions = options || (this.onChangeOptions && this.onChangeOptions.cultureChange);
				if (cultureChangeOptions) {
					const cultureInput = appStore && appStore.appParamsHelper && appStore.appParamsHelper.inputs && appStore.appParamsHelper.inputs['culture'];
					const newCulture = selectionStore.getConditionalValueFieldValue(cultureChangeOptions, values, cultureInput);
					if (translationStore.culture !== newCulture) {
						await translationStore.setCulture(newCulture);
					}
				}
			}
			if (disclaimerStore) {
				disclaimerStore.loadDisclaimers();
			}
			if (acceptanceDisclaimerStore) {
				acceptanceDisclaimerStore.loadDisclaimers();
			}
		}
	}
}