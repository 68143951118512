import { helpers, models } from '@kurtosys/ksys-app-template';
import { computed, observable } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IEventTypeCache, IListenForOptions } from '../../../models/app/IListenForOptions';
import { ILogOptions, TLogType } from '../../../utils/log';
import stringify from 'json-stable-stringify';

type IBaseEventDetail = models.eventBus.IBaseEventDetail;
type ISourceApplication = models.eventBus.ISourceApplication;
type EventBusStoreCallback<TDetail extends IBaseEventDetail> = (customEvent: CustomEvent<TDetail>) => Promise<void>;
type EventBusStoreCallbacks<TDetail extends IBaseEventDetail> = Partial<Record<models.eventBus.EventType, EventBusStoreCallback<TDetail>>>;
interface IListenOptions<TDetail extends IBaseEventDetail> {
	callback: EventBusStoreCallback<TDetail>;
	updateRequiredTracking?: boolean;
	runOnce?: boolean;
}

export class EventBusStore extends StoreBase {

	callbacks: EventBusStoreCallbacks<any> | undefined;
	eventCache: Map<string, string> = new Map();
	helper: helpers.EventBusHelper | undefined;
	@observable.ref requiredCallbackTracking: Record<string, boolean> = {};

	@computed
	get listenFor(): IListenForOptions[] {
		const legacyEventAppIds = this.storeContext.appStore.globalInputIdentifiers || [];
		const listenFor = this.storeContext.appStore.listenFor;
		// Add support for legacy globalInputIdentifiers
		if (legacyEventAppIds.length > 0) {
			const globalInputsChangeName = models.eventBus.EventType.globalInputsChange;
			for (const appId of legacyEventAppIds) {
				const existingListener = listenFor.find(listener => listener.appId === appId);
				if (!existingListener) {
					listenFor.push({
						appId,
						eventTypes: [
							{
								name: globalInputsChangeName,
							},
						],
					});
				}
				else if (!existingListener.eventTypes.find(type => type.name === globalInputsChangeName)) {
					existingListener.eventTypes.push({
						name: globalInputsChangeName,
					});
				}
			}
		}
		return listenFor;
	}

	@computed
	public get requiredCallbacksCompleted() {
		if (Object.keys(this.requiredCallbackTracking).length === 0) {
			return true;
		}

		let completed = true;
		for (const name in this.requiredCallbackTracking) {
			if (!this.requiredCallbackTracking[name]) {
				completed = false;
				break;
			}
		}
		return completed;
	}

	setupHelper() {
		const { appStore } = this.storeContext;
		this.helper = new helpers.EventBusHelper(appStore.applicationId);
	}

	async initialize(callbacks: EventBusStoreCallbacks<any> | undefined) {
		const { inputStore } = this.storeContext;
		this.callbacks = {
			[models.eventBus.EventType.globalInputsChange]: inputStore.handleUpdate,
			...callbacks,
		};
	}

	isListeningFor(appId: string, eventName: string): boolean {
		const app = this.listenFor.find((appConfig) => {
			return appConfig.appId === appId;
		});
		const eventConfig = app && app.eventTypes.find((eventConfig) => {
			return eventConfig.name === eventName;
		});

		return Boolean(eventConfig);
	}

	/**
	 * Start listening for events that are required for initial load
	 */
	public async register() {
		const listenForEvents = this.listenFor;
		if (!this.helper) {
			this.setupHelper();
		}

		if (listenForEvents.length > 0 && this.helper !== undefined) {
			listenForEvents.forEach((listenForEvent) => {
				const appId = listenForEvent.appId;
				const appEventTypes = listenForEvent.eventTypes;

				if (appEventTypes.length > 0) {
					appEventTypes.forEach((appEvent) => {
						const eventType = appEvent.name as models.eventBus.EventType;
						const requiredForInitialLoad = appEvent.requiredForInitialLoad;
						const runOnce = appEvent.runOnce;
						const cache = appEvent.cache;
						// Wait for the eventBus Store to retrieve all events that are required before continuing with initialzing of the app
						const targetCallback = this.callbacks && this.callbacks[eventType];
						if (targetCallback) {
							const storageValue = this.getStorageValue(appId, eventType, cache);

							if (storageValue && this.hasEventDetailChanged(storageValue.detail, eventType)) {
								this.log('debug', { message: `Found storage for app ${ appId } and event ${ eventType }` });
								targetCallback(storageValue);
							}

							// Checks if the event is required and if we already have the event, then we don't need to set up additional tracking for it
							let updateRequiredTracking = false;
							if (!storageValue && requiredForInitialLoad) {
								updateRequiredTracking = true;
								this.requiredCallbackTracking = { ...this.requiredCallbackTracking, [`${ appId }-${ eventType }`]: false };
								this.log('debug', { message: `Register require event ${ eventType }` });
							}

							// add an event listener if the runOnce condition hasn't already been met.
							if (!runOnce || (runOnce && !storageValue)) {
								this.log('debug', { message: `Register event ${ eventType }` });
								this.listen(appId, eventType, { runOnce, updateRequiredTracking, callback: targetCallback });
							}
						}
					});
				}
			});
		}
	}

	/**
	 * Trigger event types passing the appropriate event detail, this method also handles common functionality like appending source application details.
	 *
	 * @template T
	 * @param type
	 * @param eventInit
	 */
	public trigger<T extends IBaseEventDetail>(type: models.eventBus.EventType, eventInit: CustomEventInit<T>) {
		if (this.helper) {
			// Append current application details as the source application for the event being triggered
			if (eventInit.detail && !eventInit.detail.sourceApplication) {
				const { manifest, applicationGuid, configurationKey, styleKey, themeKey, applicationId } = this.storeContext.appStore;
				eventInit.detail.sourceApplication = {
					templateId: manifest.ksysAppTemplateId,
					applicationId: applicationId || manifest.ksysAppTemplateId,
					applicationCode: applicationGuid || '',
					configurationKey: configurationKey || 'default',
					styleKey: styleKey || 'default',
					themeKey: themeKey || 'default',
				};
			}
			this.helper.trigger(type, eventInit);
		}
		else {
			this.log('warning', {
				message: `Unable to trigger event, as no the helper not yet defined. The helper is defined as part of the event bus store register.`,
				detail: {
					type,
					eventDetail: eventInit,
				},
			});
		}
	}

	/**
	 * Generate an application identifier based on sourced application details, which are derived from props and configuration of the source app.
	 *
	 * @param application
	 * @returns
	 */
	public generateSourceAppKey(application: ISourceApplication): string {
		const { applicationCode, applicationId, configurationKey, themeKey, templateId, styleKey } = application;
		const parts = [];
		if (templateId) {
			parts.push(`ti:${ templateId }`);
		}
		if (configurationKey) {
			parts.push(`ck:${ configurationKey }`);
		}
		if (styleKey) {
			parts.push(`sk:${ styleKey }`);
		}
		if (themeKey) {
			parts.push(`tk:${ themeKey }`);
		}
		if (applicationCode) {
			parts.push(`ac:${ applicationCode }`);
		}
		if (applicationId) {
			parts.push(`ai:${ applicationId }`);
		}
		return parts.join('-');
	}

	/**
	 * Extract the application key from the received custom application event, which is a unique built up based on application details.
	 * 
	 * This will fallback to the application id which is also derived off of the event.
	 *
	 * @private
	 * @template T
	 * @param event
	 * @returns
	 */
	public getSourceAppKeyFromEvent<T extends IBaseEventDetail>(event: CustomEvent<T>): string {
		const { detail } = event;
		let key = '';
		if (detail) {
			if (detail.sourceApplication) {
				key = this.generateSourceAppKey(detail.sourceApplication);
			}
		}
		if (key === '') {
			key = this.getSourceAppIdFromEvent(event);
		}
		return key;
	}

	/**
	 * Retrieves the application id from event detail's application source or the legacy identifier field
	 *
	 * @private
	 * @template T
	 * @param event
	 * @returns
	 */
	public getSourceAppIdFromEvent<T extends IBaseEventDetail>(event: CustomEvent<T>): string {
		const { detail } = event;
		let id = '';
		const { sourceApplication } = detail;
		if (sourceApplication && sourceApplication.applicationId) {
			id = sourceApplication.applicationId;
		}
		else if (sourceApplication && sourceApplication.templateId) {
			id = sourceApplication.templateId;
		}
		// Fallback to legacy
		if (id === '') {
			const legacyDetail = detail as Record<string, any>;
			if (legacyDetail.identifier) {
				id = legacyDetail.identifier;
			}
		}
		return id;
	}

	/**
	 * Determines Validates the to see if the last received event detail for a given event type is the same as the new event detail, regardless of source application.
	 * 
	 * Note: This then keeps track of the state of the event type's last received event detail, and thus allows us to ignore duplicate updates.
	 *
	 * @param eventValue
	 * @param appId
	 * @param eventType
	 * @returns
	 */
	private hasEventDetailChanged(eventValue: Record<string, any>, eventType: string) {
		const key = eventType;
		const evtValue = stringify(eventValue);
		this.log('debug', { message: `event cache key ${ key }` });
		if (!this.eventCache.has(key) || this.eventCache.get(key) !== evtValue) {
			this.eventCache.set(key, evtValue);
			return true;
		}
		return false;
	}

	private getStorageValue(appId: string, eventType: models.eventBus.EventType, cache?: IEventTypeCache) {
		if (cache && cache.enabled === false) {
			return;
		}
		const eventDetails = sessionStorage.getItem('eventDetails');
		if (eventDetails) {
			try {
				const parsedDetails = JSON.parse(eventDetails);
				if (parsedDetails && parsedDetails[appId] && parsedDetails[appId][eventType]) {
					return parsedDetails[appId][eventType];
				}
			}
			catch (e) {
				this.log('debug', {
					message: `Failed to parse event storage for ${ eventType }`,
				});
			}
		}
		return;
	}

	private log = (eventType: TLogType, options: ILogOptions) => {
		const { appStore } = this.storeContext;
		const { log } = appStore;
		log(eventType, {
			...options,
			additionalContext: 'Event Bus Store',
		});
	}

	private listen<T extends IBaseEventDetail>(targetAppId: string, type: models.eventBus.EventType, options: IListenOptions<T>) {
		const helper = this.helper;
		if (helper) {
			const { callback, updateRequiredTracking: updatedRequiredTracking = false, runOnce = false } = options;
			const eventHandler = (evt: Event) => {
				const customEvent = evt as CustomEvent<T>;

				// Add limited support for legacy app triggers of ksys-app-initialised (should be removed once all apps have upgraded).
				if (type === 'ksys-app-initialized') {
					if (!customEvent.detail.sourceApplication) {
						const eventDetail = customEvent.detail as Record<string, any>;
						customEvent.detail.sourceApplication = {
							applicationCode: eventDetail.appGuid || '',
							templateId: eventDetail.templateId || '',
							applicationId: eventDetail.identifier || eventDetail.templateId || '',
							configurationKey: eventDetail.configKey || 'default',
							styleKey: eventDetail.styleKey || 'default',
							themeKey: eventDetail.themeKey || 'default',
						};
					}
				}

				const eventSourceAppId = this.getSourceAppIdFromEvent(customEvent);
				this.log('debug', {
					message: 'Event Listener Triggered',
					detail: {
						eventSourceAppId,
						targetAppId,
						customEvent,
					},
				});
				if (eventSourceAppId === targetAppId) {
					if (runOnce) {
						helper.unregister(type, eventHandler);
					}
					if (updatedRequiredTracking) {
						this.requiredCallbackTracking = { ...this.requiredCallbackTracking, [`${ targetAppId }-${ type }`]: true };
					}
					if (this.hasEventDetailChanged(customEvent.detail, type)) {
						callback(customEvent);
					}
				}
			};
			helper.register(type, eventHandler);
		}
		else {
			this.log('warning', {
				message: `Unable to register event type "${ type }", as the Event Bus Helper is not yet defined. It is defined as part of the event bus store register method.`,
			});
		}
	}
}