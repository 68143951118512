import React, { Component } from 'react';
import {
	IAcceptanceDisclaimerState,
	IAcceptanceDisclaimerProps,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Heading from './styledComponents/Heading';
import List from './styledComponents/List';
import ListItem from './styledComponents/ListItem';
import Content from './styledComponents/Content';
import { IAcceptanceDisclaimerContent } from './models/IAcceptanceDisclaimerContent';
import Translate from '@kurtosys/ksys-app-components/dist/components/base/Translate';
import Wrapper from './styledComponents/Wrapper';

@inject('appStore', 'acceptanceDisclaimerStore')
@observer
export class AcceptanceDisclaimer extends Component<
	IAcceptanceDisclaimerProps,
	IAcceptanceDisclaimerState
> {
	static configurationKey: 'acceptanceDisclaimer' = 'acceptanceDisclaimer';
	static styleKey: 'acceptanceDisclaimer' = 'acceptanceDisclaimer';
	constructor(props: IAcceptanceDisclaimerProps) {
		super(props);
	}
	render() {
		const { className, acceptanceDisclaimerStore, appStore } = this.props;
		const disclaimers =
			(acceptanceDisclaimerStore && acceptanceDisclaimerStore.disclaimers) ||
			[];
		const heading =
			(acceptanceDisclaimerStore && acceptanceDisclaimerStore.heading) || '';

		if (!appStore || !appStore.hasSelectionsAndDisclaimers) {
			return null;
		}
		return (
			<Wrapper className={className}>
				<Heading>
					<Translate>{heading}</Translate>
				</Heading>
				<List>
					{disclaimers.map(
						(disclaimer: IAcceptanceDisclaimerContent, index) => (
							<ListItem key={index}>
								<Content
									dangerouslySetInnerHTML={{ __html: disclaimer.content }}
								/>
							</ListItem>
						)
					)}
				</List>
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(
	AcceptanceDisclaimer,
	'acceptanceDisclaimer'
);
